import React from "react";

function Experience() {
	return (
		<div>
			<h1>Experience Page</h1>
			{/* Add content for the Experience page */}
		</div>
	);
}

export default Experience;
