import React from "react";

function Contact() {
	return (
		<div>
			<h1>Contact Page</h1>
			{/* Add content for the Contact page */}
		</div>
	);
}

export default Contact;
